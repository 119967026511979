import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { initializeFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import 'i18n';
import 'assets/fonts/dreamscreen-fonts.scss';
import 'assets/fonts/open-sans.scss';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const database = getDatabase(app);
export const firestore = initializeFirestore(app, { ignoreUndefinedProperties: true });
export const storage = getStorage(app);
export const functions = getFunctions(app, 'europe-west3');

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

sentryDsn &&
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });

function importBuildTarget() {
  if (process.env.REACT_APP_ENTRYPOINT === 'tcow') {
    return import('AppTemplateCow');
  } else {
    return import('AppManor');
  }
}

importBuildTarget().then(({ default: App }) =>
  ReactDOM.render(
    <Suspense fallback={<div>Loading... </div>}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Suspense>,
    document.getElementById('root')
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
